@import url("https://fonts.googleapis.com/css?family=Raleway");

@font-face {
  font-family: "FranklinGothic";
  src: url("../public/fonts/FranklinGothicBookRegular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "FranklinGothic, Relaway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  text-align: left;
  min-height: 35px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0.5rem 0.5rem !important;
}

.css-exruyu {
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  border-radius: 16px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #0b1f2e;
  border-radius: 8px;
  outline: 1px solid rgb(116, 116, 116);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1a556d;
  border-radius: 16px;
  outline: 1px solid rgb(116, 116, 116);
}

.css-66dh3a-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}
