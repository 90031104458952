body {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100vh;
}

a {
  color: #3e6779 !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.App {
  text-align: center;
}
